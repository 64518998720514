<template>
  <div>
    <span>
      <validation-observer ref="form3Update">
        <b-form>
          <head-title-wizard
            :section-player="sectionPlayer"
            :section-coaching-staff="sectionCoachingStaff"
            :option="option"
            :gender="genderSelect ? genderSelect : '' "
          />
          <b-row>
            <b-col
              sm="12"
              md="12"
              lg="12"
            >
              <b-row align-h="center">
                <b-col>
                  <div class="text-center">
                    <upload-image
                      :tooltip-text="'generic.allowedFormat'"
                      :button-text="'generic.uploadPhoto'"
                      :avatar-icon="'icon-0-icons-dark-avatar'"
                      :image-size="'10rem'"
                      :apply-b-link="true"
                      :disabled="member.club_hash == null"
                      :path-file-avatar="member.avatar_file ? member.avatar_file.url_thumb : ''"
                      @intance-file-x="fileValidation($event, 'avatar_file')"
                    />
                    <br>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-row>
                <b-col>
                  <!-- toggle button -->
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-toggle.collapse-Identificacion
                    class="w-100"
                    variant="outline-primary"
                  >
                    {{ $t('member.form.label.identification') }}
                  </b-button>

                  <b-collapse
                    id="collapse-Identificacion"
                    visible
                    class="mt-2"
                  >

                    <!-- Form identification -->
                    <b-row align-h="center">
                      <b-col>
                        <b-form-group>
                          <label>{{ $t('member.form.label.idNumber') }} <span class="text-danger">*</span></label>
                          <validation-provider
                            #default="{ errors }"
                            rules="required|max:254|alphaNumSpacesRegex"
                            name="idNumber"
                          >
                            <b-form-input
                              v-model="member.id_number"
                              type="text"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('member.form.placeholder.enterIdNumber')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row align-h="center">
                      <b-col>
                        <upload-file
                          :title="$t('member.form.label.identification')"
                          folder="members"
                          :disabled="member.club_hash == null"
                          :club-id="member.club_hash"
                          :file-title="member.file_identification ? member.file_identification.title : null"
                          @uploaded-file="assignFileIdentification"
                        />
                      </b-col>
                    </b-row>
                    <b-row align-h="center">
                      <b-col>
                        <upload-file
                          :title="$t('member.form.label.birthCertificate')"
                          folder="members"
                          :disabled="member.club_hash == null"
                          :club-id="member.club_hash"
                          :file-title="member.file_birth_certificate ? member.file_birth_certificate.title : null"
                          @uploaded-file="assignFileBirthCertificate"
                        />
                      </b-col>
                    </b-row>
                    <template v-if="member.member_type.key === sectionCoachingStaff">
                      <b-row align-h="center">
                        <b-col>
                          <upload-file
                            :title="$t('title-or-licence')"
                            folder="members"
                            :disabled="member.club_hash == null"
                            :club-id="member.club_hash"
                            :file-title="member.file_title_or_licence ? member.file_title_or_licence.title : ''"
                            @uploaded-file="assignFileTitleOrLicence"
                          />
                        </b-col>
                      </b-row>
                    </template>
                  </b-collapse>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-row>
                <b-col class="mb-30">
                  <!-- toggle button -->
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-toggle.collapse-nationality
                    class="w-100"
                    variant="outline-primary"
                  >
                    {{ $t('member.form.label.nacionality') }}
                  </b-button>

                  <b-collapse
                    id="collapse-nationality"
                    visible
                    class="mt-2"
                  >
                    <div>
                      <div>
                        <b-form
                          ref="form-nationality-pasport"
                          @submit.prevent="addNationality"
                        >

                          <!-- Row Loop -->
                          <b-row
                            v-for="(item, index) in member.nationalities"
                            :id="item.id"
                            :key="item.id"
                            ref="row-nationality"
                          >
                            <b-col md="12">
                              <b-form-group>
                                <label>{{ $t('member.form.label.nacionality') }} <span class="text-danger">*</span></label>
                                <validation-provider
                                  #default="{ errors }"
                                  rules="required"
                                  name="nationality"
                                >
                                  <v-select
                                    id="club-country-id"
                                    v-model="member.nationalities[index].nationality_hash"
                                    label="name"
                                    :options="catalogNationalities"
                                    :placeholder="$t('generic.selectCountry')"
                                    :reduce="country => country.id"
                                  >
                                    <!-- {{catalogNationalities}} -->
                                    <div slot="no-options">
                                      {{ $t('generic.noRecordsFound') }}
                                    </div>
                                  </v-select>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>

                            <b-col md="12">
                              <b-form-group>
                                <label>{{ $t('member.form.label.withPassport') }}</label><br>
                                <label for="passport"> {{ $t('generic.not') }} </label>
                                <b-form-checkbox
                                  v-model="member.nationalities[index].with_passport"
                                  :checked="member.nationalities[index].with_passport"
                                  :value="true"
                                  :unchecked-value="'false'"
                                  name="check-button"
                                  class="ml-2"
                                  switch
                                  inline
                                />
                                <label
                                  class="pb-1"
                                  for="passport"
                                > {{ $t('generic.yes') }} </label>
                              </b-form-group>
                            </b-col>
                            <div
                              v-if="member.nationalities[index].with_passport === true"
                              class="col-md-12"
                            >

                              <b-col
                                class="pl-0 mt-18"
                              >
                                <b-form-group>
                                  <label>{{ $t('member.form.label.passportNumber') }} <span class="text-danger">*</span></label>
                                  <validation-provider
                                    #default="{ errors }"
                                    rules="required|max:254|alphaNumSpacesRegex"
                                    name="passportNumber"
                                  >
                                    <b-form-input
                                      v-model="member.nationalities[index].passport_number"
                                      type="text"
                                      :state="errors.length > 0 ? false : null"
                                      :placeholder="$t('member.form.placeholder.enterVisaNumber')"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col class="pl-0">
                                <upload-file
                                  :title="$t('member.form.label.passport')"
                                  folder="members"
                                  :disabled="member.club_hash == null"
                                  :club-id="member.club_hash"
                                  :file-title="member.nationalities[index].file_passport ? member.nationalities[index].file_passport.title : null"
                                  @uploaded-file="assignFilePassport($event, index)"
                                />
                              </b-col>
                              <b-col class="pl-0">
                                <b-form-group>
                                  <label>{{ $t('member.form.label.expiration') }} <span class="text-danger">*</span></label>
                                  <validation-provider
                                    #default="{ errors }"
                                    rules="required"
                                    name="expiration"
                                  >
                                    <b-form-datepicker
                                      v-model="member.nationalities[index].passport_expiration"
                                      :locale="$i18n.locale"
                                      :min="new Date()"
                                      class="form-control"
                                      :placeholder="$t('member.form.placeholder.selectDate')"
                                      :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <!-- Main -->
                              <b-form-checkbox
                                v-model="member.nationalities[index].is_main"
                                class="custom-control-primary"
                                :checked="member.nationalities[index].is_main"
                                :value="true"
                                :unchecked-value="'false'"
                                @change="onChangeIsMain(index)"
                              >
                                <!-- :checked="modules.status" -->
                                {{ $t('generic.selectAsMain') }}
                              </b-form-checkbox>
                            </div>
                            <!-- </b-row> -->

                            <!-- Remove Button -->
                            <b-col
                              lg="12"
                              md="12"
                              class="mb-50"
                            >
                              <div class="float-right">

                                <b-button
                                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                  style="margin-left: 17px;"
                                  variant="secondary"
                                  pill
                                  @click="removeNationality(index)"
                                >
                                  {{ $t('buttons.putOff') }}
                                </b-button>

                              </div>
                            </b-col>
                            <b-col>
                              <hr>
                            </b-col>
                          </b-row>

                        </b-form>
                      </div>
                      <div>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          pill
                          :style="colorPrimaryBtn"
                          @click="addNationality"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('buttons.addNationality') }}</span>
                        </b-button>
                      </div>
                    </div>
                  </b-collapse>
                </b-col>
                <!-- </b-row>
          <b-row> -->
                <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  class="mb-30"
                >
                  <!-- toggle button -->
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-toggle.collapse-visa
                    class="w-100"
                    variant="outline-primary"
                  >
                    {{ $t('member.form.label.visa') }}
                  </b-button>
                  <b-collapse
                    id="collapse-visa"
                    visible
                    class="mt-2"
                  >

                    <div>

                      <div>
                        <b-form
                          ref="form"
                          @submit.prevent="addVisa"
                        >
                          <!-- Row Loop -->
                          <b-row
                            v-for="(item, index) in member.visas"
                            :id="item.id"
                            :key="item.id"
                            ref="row"
                          >

                            <b-col md="12">
                              <!-- Form grupo -->
                              <b-form-group>
                                <label>{{ $t('member.form.label.issuedBy') }} <span class="text-danger">*</span></label>
                                <validation-provider
                                  #default="{ errors }"
                                  rules="required"
                                  name="country"
                                >

                                  <v-select
                                    id="club-country-id"
                                    v-model="member.visas[index].expedition_country_hash"
                                    label="name"
                                    :options="countries"
                                    :reduce="country => country.id"
                                    :placeholder="$t('generic.selectCountry')"
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <div slot="no-options">
                                      {{ $t('generic.noRecordsFound') }}
                                    </div>
                                  </v-select>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>

                            </b-col>
                            <b-col
                              md="12"
                            >
                              <b-form-group>
                                <label>{{ $t('member.form.label.visaNumber') }} <span class="text-danger">*</span></label>
                                <validation-provider
                                  #default="{ errors }"
                                  rules="required|max:254|alphaNumSpacesRegex"
                                  name="visaNumber"
                                >
                                  <b-form-input
                                    v-model="member.visas[index].visa_number"
                                    type="text"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="$t('member.form.placeholder.enterVisaNumber')"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>

                            </b-col>

                            <!-- Type of visa -->
                            <b-col
                              md="12"
                            >
                              <b-form-group>
                                <label>{{ $t('member.form.label.typeOfVisa') }} <span class="text-danger">*</span></label>
                                <validation-provider
                                  #default="{ errors }"
                                  rules="required|max:254|alphaNumSpacesRegex"
                                  name="typeOfVisa"
                                >
                                  <b-form-input
                                    v-model="member.visas[index].type_of_visa"
                                    type="text"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="$t('member.form.placeholder.typeOfVisa')"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>

                            </b-col>
                            <!-- End type of visa -->

                            <b-col md="12">
                              <upload-file
                                :title="$t('member.form.label.visa')"
                                folder="members"
                                :disabled="member.club_hash == null"
                                :club-id="member.club_hash"
                                :file-title="member.visas[index].file_visa ? member.visas[index].file_visa.title : ''"
                                @uploaded-file="assignFileVisas($event, index)"
                              />
                            </b-col>
                            <b-col md="12">
                              <b-form-group>
                                <label>{{ $t('member.form.label.expiration') }} <span class="text-danger">*</span></label>
                                <validation-provider
                                  #default="{ errors }"
                                  rules="required"
                                  name="expiration"
                                >
                                  <b-form-datepicker
                                    v-model="member.visas[index].visa_expiration"
                                    :locale="$i18n.locale"
                                    :min="new Date()"
                                    class="form-control"
                                    :placeholder="$t('member.form.placeholder.selectDate')"
                                    :state="errors.length > 0 ? false : null"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <!-- </b-row> -->
                            <!-- Remove Button -->
                            <b-col
                              lg="12"
                              md="12"
                              class="mb-50"
                            >
                              <div class="float-right">
                                <!-- button delete -->
                                <b-button
                                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                  style="margin-left: 17px;"
                                  variant="secondary"
                                  pill
                                  @click="removeVisa(index)"
                                >
                                  {{ $t('buttons.putOff') }}
                                </b-button>
                              </div>
                            </b-col>
                            <b-col cols="12">
                              <hr>
                            </b-col>
                          </b-row>
                        </b-form>
                      </div>
                      <div>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          pill
                          @click="addVisa"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('buttons.addVisa') }}</span>
                        </b-button>
                      </div>
                    </div>
                  </b-collapse>
                </b-col>
                <!-- <b-col /> -->
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <div class="btn-footer-modal">

        <!-- Buttons cancel -->
        <b-button
          variant="secondary"
          pill
          style="margin-left: 17px;"
          @click="$emit('hidden-form')"
        >
          {{ $t('buttons.cancel') }}
        </b-button>

        <!-- button save -->
        <overlay-button :loading="isLoading">
          <b-button
            variant="primary"
            pill
            style="margin-left: 17px;"
            :style="colorPrimaryBtn"
            @click="onUpdateMember"
          >
            {{ $t("buttons.save") }}
          </b-button>
        </overlay-button>

      </div>
      <div class="float-right">
        <b-button
          v-if="checkDeleted"
          variant="outline-secondary"
          pill
          style="margin-left: 17px;"
          class="d-none d-sm-none d-md-block"
          @click="onRemoveMember"
        >
          <i class="icon-0-icons-dark-trash-can mr-25" />
          <span class="align-middle">{{ $t('buttons.delete') }}</span>
        </b-button>
      </div>
      <b-button
        v-if="checkDeleted"
        class="d-block d-sm-block d-md-none w-100 mt-2"
        variant="outline-secondary"
        pill
        @click="onRemoveMember"
      >
        <i class="icon-0-icons-dark-trash-can mr-25" />
        <span class="align-middle">{{ $t('buttons.delete') }}</span>
      </b-button>
    </span>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import UploadFile from '@/views/member/components/UploadFile.vue'
// import OverlayContent from '@/views/partials/OverlayContent.vue'
import { validatorFileSize, validatorFileType } from '@core/utils/validations/validators'
import OverlayButton from '@/views/partials/OverlayButton.vue'
import checkPermission from '@/auth/permissions'
import { mapActions } from 'vuex'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import UploadImage from '../partials/UploadImageAvatar.vue'
import HeadTitleWizard from './components/HeadTitleWizard.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    UploadImage,
    UploadFile,
    // OverlayContent,
    OverlayButton,
    HeadTitleWizard,
  },
  directives: {
    Ripple,
  },
  props: {
    member: {
      type: Object,
      default: null,
    },
    countries: {
      type: Array,
      default: null,
    },
    catalogNationalities: {
      type: Array,
      default: null,
    },
    sectionPlayer: {
      type: String,
      default: null,
    },
    sectionCoachingStaff: {
      type: String,
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
    genderSelect: {
      type: String,
      default: null,
    },
    option: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      required,
      email,
      idCountry: null,
      filters: {
        page: 1,
        perPage: 10,
      },
      nationalities: {
        id: null,
        hash: null,
        nationality_hash: null,
        with_passport: true,
        file_passport: {},
        path_file_passport_media_file_hash: null,
        path_file_passport_media_file_id: null,
        passport_number: null,
        passport_expiration: null,
        is_main: false,
        member_hash: null,
      },
      visas: {
        id: null,
        hash: null,
        expedition_country_hash: null,
        file_visa: {},
        path_file_visa_media_file_hash: null,
        path_file_visa_media_file_id: null,
        visa_number: null,
        type_of_visa: null,
        visa_expiration: null,
        member_hash: null,
      },
    }
  },
  computed: {
    checkDeleted() {
      const { key } = this.member.member_type
      if (key === this.sectionPlayer) {
        return this.checkPermission(['DELETE_MEMBERS_PLAYERS', 'ALLOW_ALL'])
      }
      if (key === this.sectionCoachingStaff) {
        return this.checkPermission(['DELETE_MEMBERS_COACHING_STAFF', 'ALLOW_ALL'])
      }
      return false
    },
  },
  methods: {
    checkPermission,
    validatorFileSize,
    validatorFileType,
    ...mapActions({
      update: 'memberStore/update',
      destroy: 'memberStore/destroy',
      mediaFileUpload: 'mediaFile/upload',
      mediaFileDestroy: 'mediaFile/destroy',
    }),
    async onUpdateMember() {
      this.isLoading = true
      this.$refs.form3Update.validate().then(async isValid => {
        if (!isValid && this.member.is_complete_data === false) {
          this.showError(this.$t('fields-are-missing-to-complete'))
          this.isLoading = false
          return
        }
        await this.update(this.member).then(response => {
          this.responseSuccessUpdate(response.data.message)
          this.$emit('hidden-form')
          this.isLoading = false
        }).catch(error => {
          this.responseCatch(error)
        }).finally(() => {
          this.isLoading = false
        })
      })
    },
    async onRemoveMember() {
      await this.sampleDeleteQuestion()
        .then(async result => {
          if (!result.isConfirmed) {
            return
          }
          await this.destroy(this.member.hash).then(response => {
            this.responseSuccessElimination(response.data.message)
            this.$router.push({ name: 'member' })
          })
        }).catch(error => {
          this.responseCatch(error)
        })
    },
    addNationality() {
      this.member.nationalities.push({
        id: Date.now(),
        hash: null,
        nationality_hash: this.nationalities.nationality_hash,
        with_passport: this.nationalities.with_passport,
        file_passport: {},
        path_file_passport_media_file_hash: this.nationalities.path_file_passport_media_file_hash,
        passport_number: this.nationalities.passport_number,
        passport_expiration: this.nationalities.passport_expiration,
        is_main: this.nationalities.is_main,
        member_hash: this.member.hash,
      })
    },
    removeNationality(index) {
      this.member.nationalities.splice(index, 1)
    },
    addVisa() {
      this.member.visas.push({
        id: Date.now(),
        hash: null,
        expedition_country_hash: this.visas.expedition_country_hash,
        file_visa: {},
        path_file_visa_media_file_hash: this.visas.path_file_visa_media_file_hash,
        type_of_visa: this.visas.type_of_visa,
        visa_number: this.visas.visa_number,
        visa_expiration: this.visas.visa_expiration,
        member_hash: this.member.hash,
      })
    },
    removeVisa(index) {
      this.member.visas.splice(index, 1)
    },
    onChangeIsMain(index) {
      this.member.nationalities.forEach((item, key) => {
        if (key !== index) {
          item.is_main = 0
        }
      })
    },
    assignFileVisas(responseFile, index) {
      const previousFileHash = this.member.visas[index].path_file_visa_media_file_hash
      if (previousFileHash != null) {
        this.mediaFileDestroy(previousFileHash)
          .then(() => {
          }).catch(error => {
            this.responseCatch(error)
          })
      }
      this.$set(this.member.visas[index], 'file_visa', responseFile)
      this.$set(this.member.visas[index], 'path_file_visa_media_file_hash', responseFile.hash)
    },
    assignFilePassport(responseFile, index) {
      const previousFileHash = this.member.nationalities[index].path_file_passport_media_file_hash
      if (previousFileHash != null) {
        this.mediaFileDestroy(previousFileHash)
          .then(() => {
          }).catch(error => {
            this.responseCatch(error)
          })
      }
      this.$set(this.member.nationalities[index], 'file_passport', responseFile)
      this.$set(this.member.nationalities[index], 'path_file_passport_media_file_hash', responseFile.hash)
    },
    assignFileIdentification(responseFile) {
      const previousFileHash = this.member.path_file_identification_media_file_hash
      if (previousFileHash != null) {
        this.mediaFileDestroy(previousFileHash)
          .then(() => {
          }).catch(error => {
            this.responseCatch(error)
          })
      }
      this.$set(this.member, 'file_identification', responseFile)
      this.$set(this.member, 'path_file_identification_media_file_hash', responseFile.hash)
    },
    assignFileBirthCertificate(responseFile) {
      const previousFileHash = this.member.path_file_birth_certificate_media_file_hash
      if (previousFileHash != null) {
        this.mediaFileDestroy(previousFileHash)
          .then(() => {
          }).catch(error => {
            this.responseCatch(error)
          })
      }
      this.$set(this.member, 'file_birth_certificate', responseFile)
      this.$set(this.member, 'path_file_birth_certificate_media_file_hash', responseFile.hash)
    },
    assignFileTitleOrLicence(responseFile) {
      const previousFileHash = this.member.path_file_title_or_licence_media_file_hash
      if (previousFileHash != null) {
        this.mediaFileDestroy(previousFileHash)
          .then(() => {
          }).catch(error => {
            this.responseCatch(error)
          })
      }
      this.$set(this.member, 'file_title_or_licence', responseFile)
      this.$set(this.member, 'path_file_title_or_licence_media_file_hash', responseFile.hash)
    },
    async fileValidation(fileData, typeField) {
      // const validSize = this.validatorFileSize(fileData)
      const validType = this.validatorFileType(fileData)

      // if (!validSize) {
      //   if (typeField === 'avatar_file') this.path_file_avatar = null
      //
      //   this.showError(`${this.$t('the-file-size-exceeds-the-allowed-size')}: 2MB`)
      //   return
      // }
      if (!validType) {
        if (typeField === 'avatar_file') this.path_file_avatar = null

        this.showError(`${this.$t('only-documents-in-PDF-and-image-format-are-allowed')}`)
        return
      }
      this.saveFileUpload(fileData, typeField)
    },
    async saveFileUpload(fileData, typeField) {
      const formData = new FormData()
      formData.append('file', fileData)
      formData.append('folder', 'members')
      formData.append('club_id', this.member.club_hash)
      await this.mediaFileUpload(formData).then(response => {
        const { data } = response.data
        // this.showSuccess(this.$t('generic.document'), this.$t('the-image-has-been-stored-correctly'))
        if (typeField === 'avatar_file') {
          const previousFileHash = this.member.avatar_file_media_file_hash
          if (previousFileHash != null) {
            this.mediaFileDestroy(previousFileHash)
              .then(() => {
              }).catch(error => {
                this.responseCatch(error)
              })
          }
          this.$set(this.member, 'avatar_file', data)
          this.$set(this.member, 'avatar_file_media_file_hash', data.hash)
        }
      }).catch(error => {
        this.responseCatch(error)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.repeater-form {
  overflow: visible;
  transition: .35s height;
}
.mb-30 {
  margin-bottom: 30px;
}
.btn-right {
  float: right !important;
}
.mt-18 {
  margin-top: 18px;
}
.btn-footer-modal {
    float: right
  }
@media (max-width: 450px) {
  .btn-footer-modal {
    float: none !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
</style>
